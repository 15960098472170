.dnd_main {
    display: flex;
    width: 100%;
    min-height: 100px;
    justify-content: center;
}
.dnd_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: darkgrey;
}
.dnd_right {
    width: 100%;
    padding: 10px;
    font-size: 12px;
}
.dnd-btn-copy {
    position: relative;
}
.dnd_alert_copy {
    position: absolute;
    top: 27px;
    left: -125px;
    width: 205px;
}