.label-brand {
    background: white;
    border-radius: 4px;
    min-width: 300px;
    border: 1px solid #1976d2;
    height: 36px;
}
.label-brand > p {
    padding: 5px 16px;
    margin: 0;
    color: #1976d2;
    font-size: 20px;
    font-weight: 500;
}
.label-brand_inputposition {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 3px 5px 16px;
    margin: 0;
    width: 60px;
}
.label-brand_inputposition > input {
    padding: 0;
    margin: 0;
    color: #1976d2;
    font-size: 20px;
    font-weight: 500;
    width: 50%;
    border: none;
}
.label-brand_inputposition_btn {
    cursor: pointer;
}