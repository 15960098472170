/*Style Table*/
.mp-table {
    display: flex;
    /*align-items: flex-end;*/
    padding: 15px 15px;
}
.scroll-table {
    /*overflow-x: auto;*/
    width: 100%;
}
.tableValue {
    display: table-column;
    width: 20px;
}
.table-time-value >span {
    font-size: 17px;
    line-height: 20px;
}
