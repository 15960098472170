.pagination-list {
    display: flex;
    justify-content: center;
}
.pagination-list > p {
    width: 35px;
    height: 30px;
    border: 1px black solid;
    text-align: center;
    vertical-align: middle;
}
.active-pagination {
    background-color: chartreuse;
}
.schedule-list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 10px;
    padding-top: 15px;
}
.schedule-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid black;
}
.schedule-table_title {
    text-align: center;
    background-color: antiquewhite;
    font-size: 24px;
    color: #02154f;
    font-weight: 600;
    line-height: 44px;
    text-transform: uppercase;
}
.schedule-save-item {
    background-color: white;
    padding: 16px;
    margin: 0 0 8px;
}