header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background: black;
    border: white 1px solid;
}
header nav {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-left: 15px;
}
header a {
    color: white;
    text-decoration: none;
}
header a:hover {
    color: yellow !important;
    text-decoration: underline;
}
.active-link {
    color: yellow !important;
}
.changeChanel {
    display: flex;
    gap: 10px;
    padding-right: 15px;
}
.notActiveBtn {
    border: 1px solid yellow !important;
    color: yellow !important;
}
.googlelogin-btn {
    background-color: white;
    color: #1976d2;
    border: 1px white solid;
    border-radius: 22px;
    cursor: pointer;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
}
.googlelogin-btn:hover {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.user-menu {
    position: absolute;
    top:43px;
    right: 14px;
    z-index: 1;
    display: none;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    padding: 10px;
    background-color: #f0ebe6;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border: 1px black solid;
}
.user-menu-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 1px #1976d2 solid;
    width: 100%;
    padding-bottom: 15px;
}
.user-menu-item > p {
    font-style: italic;
    margin: 0;
}
.user-menu-item > span {
    font-size: 18px;
    text-decoration: underline;
    color: #1976d2;
}