.start-end-date {
    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    min-width: 628px;
    padding: 15px;
}
.start-end-date > button {
    width: 100%;
    padding: 5px;
}
.start-end-date > .MuiInputBase-input {
    padding: 0;
}