.all-mp-list {
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.brand-name-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    gap: 10px;
}
.btn-add-mp{
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
    gap: 25px;
}
.mp-active-circle {
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: green;
    box-shadow: inset 0px 0px 7px black, 2px 2px 20px black;
    cursor: pointer;
}
.mp-notActive-circle {
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    box-shadow: inset 0px 0px 7px black, 2px 2px 20px black;
    cursor: pointer;
}
.mp-sort-menu {
    list-style: none;
    margin: 5px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}
.mp-sort-menu > li {
    display: inline;
    color: #1976d2;
    cursor: pointer;
    padding-right: 15px;
}
.mp-sort-menu > li::after {
    padding-left: 15px;
    color: #1976d2;
    content: "|";
}

.mp-sort-menu > li:last-child::after {
    display: none;
}