.settings_page {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.choose-btns {
    display: flex;
    gap: 5px;
}