footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    height: 50px;
    border: white 1px solid;
}
footer > span {
    color: white;
}