.mp-brand {
    display: flex;
    flex-direction: column;
}
.mp-brand-form {
    flex-wrap: nowrap;
}
.mp-brand-form-clips {
    display: flex;
    gap: 25px;
    padding-top: 10px;
}
.mp-brand-form-clip_short {
    width: 75px;
}
.mp-brand-form-clip_long {
    width: 350px;
}
.mp-brand-form-clips_btn {
    padding-top: 35px;
}
.mp-brand_clips {
    padding-top: 25px;

}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: none !important;
}