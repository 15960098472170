.selectes-dates {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
.add-calendar {
    width: 700px;
    min-width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.add-calendar > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    height: 50px;
    font-size: 24px;
    border: 1px solid black;
}
.addBanner-table_dayName {
    font-weight: 600;
}
.addBanner-table_value {
    font-weight: 400;
}