.mainpage-table_data {
    padding: 3px;
    min-widthwidth: 0px;
    height: 25px;
    border-bottom: 1px solid black;
    font-size: 15px;
    font-weight: 400;
    position: relative;
}
.mainpage-table_rek {
    position: absolute;
    width: 200px;
    background-color: blue;
    color: white;
    top: 30px;
    left: 37px;
    display: none;
    z-index: 1;
}
.active {
    display: flex;
    flex-direction: column;
}
.maxBorder {
    border: 3px dashed palevioletred;
}