body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
main {
  display: flex;
  justify-content: center;
}
.container {
  margin: 50px 0;
  width: 90%;
  min-height: 400px;
  background: #f0ebe6;
  padding-bottom: 40px;
}
.up-icon {
  position: fixed;
  right: 30px;
  top: 93vh;
}
h2 {
  font-size: 30px;
  color: blue;
  font-weight: 600;
}