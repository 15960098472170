.settings_page {
    padding: 15px;
}
.settings-head {
    color: #1976d2;
    font-size: 24px;
    font-weight: 500;
    padding: 30px;
    justify-content: center;
}
.settings-table {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
}
.settings-table > div {
    display: flex;
}
.settings-table_left {
    width: 15vw;
    font-size: 20px;
    font-weight: 400;
}
.settings-table_right {
    width: 70vw;
    font-size: 20px;
    font-weight: 400;
}
.settings-table_input {
    width: 100%;
}
.settings-day-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}
.settings-day-blocks {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.settings-day-blocks_items {
    display: flex;
    gap: 10px;
    width: 200px;
    justify-content: space-evenly;
}
.settings-day-block_input {
    width: 100px;
}