.mainpage-table {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 40px 0;
}
.mainpage-table_time {
    display: flex;
    border: 1px solid black;
    color: ora;
}
.mainpage-table_time-value {
    display: flex;
    flex-direction: column;
    width: 60px;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
}
.mainpage-table_time > div {
    display: flex;
    flex-direction: column;
}
.mainpage-table_header {
    width: 30px;
    height: 25px;
    border-bottom: 2px solid black;
    font-size: 15px;
    font-weight: 500;
    padding: 3px;
}
.deepskyblue {
    background-color: deepskyblue !important;
}
.green {
    background-color: lawngreen !important;
}
.yellow {
    background-color: yellow !important;
}
.orange {
    background-color: orange !important;
}
.red {
    background-color: red !important;
}
.black {
    background-color: black !important;
    color: white;
}