.users-settings_page {
    padding: 30px;
}
.users-settings_page > p {
    font-size: 30px;
    font-weight: 700;
    color: #1976d2;
    text-decoration: underline;
}
.users-settings_page > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.add-user-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: relative;
}
.add-user-form_btnclose {
    position: absolute;
    color: #1976d2;
    top: -22px;
    right: -23px;
    cursor: pointer;
}
.add-user-form_input {
    width: 80%;
}