.mp-head {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px;
}
.mp-head-left {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.mp-head-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /*background: 1px chartreuse;*/
    border: 1px solid black;
    min-width: 150px;
    padding: 10px;
}
.mp-head-right > span {
    padding-top: 10px;
}

/*Style Table*/
.mp-table {
    display: flex;
    /*align-items: flex-end;*/
    padding: 15px 15px;
}
.scroll-table {
    /*overflow-x: auto;*/
    width: 100%;
}
.tableValue {
    display: table-column;
    width: 20px;
}
/*.table-time-value {*/
/*    width: 50px;*/
/*    gap: 8.67px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-end;*/
/*    align-items: center;*/
/*    padding-bottom: 8px;*/
/*}*/
/*.table-time-value >span {*/
/*    font-size: 17px;*/
/*    line-height: 20px;*/
/*}*/
/* FUCK */
.div-table {
    display: table;
    width: auto;
    background-color: #eee;
    border: 1px solid #666666;
    border-spacing: 5px; /* cellspacing:poor IE support for  this */
}
.div-table-row {
    display: table-row;
    width: auto;
    clear: both;
}
.div-table-col {
    float: left; /* fix for  buggy browsers */
    display: table-column;
    width: 22px;
    background-color: #ccc;
}
.div-table-input {
    width: 17px;
    padding: 0;
}
.name {
    width: 40px;
}
.table-inpit_style input {
    width: 20px;
    height: 20px;
    line-height: 21px;
    text-align: center;
}
.mp-head_text {
    display: flex;
}
.mp-head_text_left {
    font-size: 24px;
    font-weight: 500;
}
.mp-head_text_right {
    font-size: 24px;
    font-weight: 400;
    padding-left: 30px;
}
.mp-head_input {
    font-size: 24px;
    font-weight: 400;
    padding-left: 10px;
}