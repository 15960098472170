.set-position {
    background: white;
    border-radius: 4px;
    border: 1px solid #1976d2;
    height: 36px;
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    padding: 0 3px;
    margin: 0;
    width: 100%;
}
.set-position > input {
    padding: 0;
    margin: 0;
    color: #1976d2;
    font-size: 20px;
    font-weight: 500;
    width: 30px;
    border: none;
    text-align: center;
}
.set-position {
    cursor: pointer;
    color: green;
}