.addBanner_month {
    margin-top: 30px;
}
.calendar-table {
    display: flex;
    align-items: center;
    justify-content: center;
}
.calendar-table > table {
    width: 80%;
    font-size: 24px;
    text-align: center;
    border: 1px solid black;
}
.calendar-table tr {
    height: 50px;
}
.calendar-table td, th{
    border: 1px solid black;
}
