.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*gap: 30px;*/
}
.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-direction: column;
}
.login-enter {
    display: flex;
    justify-content: center;
    gap: 25px;
    padding-top: 30px;
}